import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import { Badge, Row, Card, Col  } from 'react-bootstrap'
import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Image from "gatsby-image"
import Sidebar from "../components/sidebar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core"

library.add(fas)

const CategoryPage = ({
    data,
    pageContext: { nextPagePath, previousPagePath, name },
}) => {
    const posts = data.allWpPost.nodes
    const firstFeaturedPost = posts.slice(0, 1)
    const twoSidebarPosts = posts.slice(1, 3)
    const moreThanThreePosts = posts.slice(3)

    if (!posts.length) {
        return (
            <Layout>
                <Seo title="All posts" />
                <Bio />
                <p>
                    No blog posts found. Add posts to your WordPress site and they'll
                    appear here!
                </p>
            </Layout>
        )
    }

    return (
        <Layout>
            <Seo title={name} />

            {/* <Bio /> */}
        <Row>
          <Col>
            <span className="badge badge-pill badge-secondary my-3">{name}</span>
          </Col>
        </Row>
        <Row>
          {/* { Featured post start} */}
          {firstFeaturedPost.map(post => {
            const title = post.title
            const featuredImage = {
              fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
              alt: post.featuredImage?.node?.alt || ``,
            }
            return (
              <div className="col-lg-8" key={post.uri}>
                <Card className="border-0">
                  {/* if we have a featured image for this post let's display it */}
                  {featuredImage?.fluid && (
                    <Image className="featuredimg"
                      fluid={featuredImage.fluid}
                      alt={featuredImage.alt}
                    />
                  )}

                  <Card.Body className="px-0">
                    <div><small>{post.date}</small></div>
                    <h4>
                      <Link to={post.uri} itemProp="url">
                        <span itemProp="headline">{parse(title)}</span>
                      </Link>
                    </h4>
                    {post.categories.nodes !== null ? post.categories.nodes.map((category, index) => (
                      <Link key={index} to={`/category/${category.slug}/`}><Badge pill variant="secondary" >{category.name}</Badge></Link>
                    )) : ""}
                    
                    <section itemProp="description" className="excerpt-info">{parse(post.excerpt)}</section>

                    
                  </Card.Body>
                </Card>
              </div>

            )
          })}
          {/* { Featured post end} */}


          {/* { Two sidebar post start} */}

          <div className="col-lg-4 col-md-12">
          <div className="row">
            <Sidebar posts={twoSidebarPosts}/>
            </div>
          </div>

          {/* { Two sidebar post end} */}
        </Row>

        <Row>
          {moreThanThreePosts.map(post => {
            const title = post.title
            const featuredImage = {
              fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
              alt: post.featuredImage?.node?.alt || ``,
            }
            return (
              <div className="col-lg-4 col-md-6" key={post.uri}>
                <Card className="border-0">
                  {/* if we have a featured image for this post let's display it */}
                  {featuredImage?.fluid && (
                    <Image
                      fluid={featuredImage.fluid}
                      alt={featuredImage.alt}
                      style={{ marginBottom: 0 }}
                    />
                  )}
                  <Card.Body className="px-0">
                    <div><small>{post.date}</small></div>
                    <h6>
                      <Link to={post.uri} itemProp="url">
                        <span itemProp="headline">{parse(title)}</span>
                      </Link>
                    </h6>
                    
                    {post.categories.nodes !== null ? post.categories.nodes.map((category, index) => (
                      <Link key={index} to={`/category/${category.slug}/`}><Badge pill variant="secondary" >{category.name}</Badge></Link>
                    )) : ""}
                  </Card.Body>
                </Card>
              </div>
            )
          })}

        </Row>


        {previousPagePath && (
          <>
            <h6><Link className="d-block px-3 bottom-text my-4" to={previousPagePath}><span className="mr-3"><FontAwesomeIcon aria-label="arrow-left" icon={["fas", "arrow-left"]} /></span> Previous page</Link></h6>
            <br />
          </>
        )}
        {nextPagePath &&
          <h6><Link className="d-block px-3 bottom-text my-4" to={nextPagePath}>Next page <span className="ml-3"><FontAwesomeIcon aria-label="arrow-right" icon={["fas", "arrow-right"]} /></span></Link></h6>
          }
      </Layout>
    )
}

export default CategoryPage

export const pageQuery = graphql`
  query WordPressPostArchive1 ($offset: Int!, $postsPerPage: Int!, $slug: String!){
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
      filter: {categories: {nodes: {elemMatch: {slug: {eq: $slug}}}}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        categories {
          nodes {
            id
            slug
            name
          }
        }
        featuredImage {
          node {
            altText
            link
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
